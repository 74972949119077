
import {computed, defineComponent, onMounted, ref} from "vue";
import SearchHorizontal from "@/components/common/SearchHorizontal.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import ProgressStatus from "@/views/training/ProgressStatus.vue";
import {GetSpaceId, PinaFilterObjects} from "@/core/composite/composite";
import {useDocUserStore} from "@/store/doc.user.store";
import TrainingUserStatus from "@/views/training/TrainingUserStatus.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import UserLink from "@/views/user/UserLink.vue";

export default defineComponent({
  name: "MyTraining",
  components: {
    UserLink,
    UserListSelect, DateTimeFormat, TrainingUserStatus, ProgressStatus, KtDatatable, SearchHorizontal},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Training",
        [
          {link: false, router: '', text: 'Training Progress'}
        ]
      )
    })
    const docUserStore = useDocUserStore();
    const page = computed(() => docUserStore.page);
    const filterObject = ref({isCompleted: false, noCompleted: true, userId: ''})
    const header = ref([
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Start Date',
        key: 'startDt',
        sortable: false,
      },
      {
        name: 'Status',
        key: 'status',
        sortable: false,
      },
      {
        name: 'Progress',
        key: 'progress',
        sortable: false
      },
    ])
    const buildHeaders = (isCompleted: boolean) => {
      if (isCompleted) {
        header.value = [
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'User',
            key: 'user',
            sortable: false,
          },
          {
            name: 'Start Date',
            key: 'startDt',
            sortable: false,
          },
          {
            name: 'Completed',
            key: 'completedDt',
            sortable: false,
          },
          {
            name: 'Status',
            key: 'status',
            sortable: false,
          },
        ]
      } else {
        header.value = [
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'User',
            key: 'user',
            sortable: false,
          },
          {
            name: 'Start Date',
            key: 'startDt',
            sortable: false,
          },
          {
            name: 'Status',
            key: 'status',
            sortable: false,
          },
          {
            name: 'Progress',
            key: 'progress',
            sortable: false
          },
        ]
      }
    }
    buildHeaders(false);
    return {
      filterObject,
      buildHeaders,
      header,
      page,
      ...GetSpaceId(),
      ...PinaFilterObjects(docUserStore, filterObject.value, ['doc', 'user'])
    }
  },
  methods: {
    searchEvent() {
      if (this.filterObject.isCompleted) {
        this.buildHeaders(true)
        this.filterObject.noCompleted = false
      } else {
        this.buildHeaders(false)
        this.filterObject.noCompleted = true
      }
      this.updateFilterObject(this.filterObject);
    },
  }
})
